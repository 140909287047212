import React from "react";


const NotFound = () => {

    return <div>NotFound</div>
}

export default NotFound;


